import { PageProps } from 'gatsby';
import React from 'react';

import BaseLayout from '../layouts/base';
import styled from 'styled-components';

const FormContainer = styled.iframe`
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const ContactPage: React.FC<PageProps> = () => (
  <BaseLayout title="Contact">
    <FormContainer
      id="iframeForm"
      title="form"
      src="https://docs.google.com/forms/d/e/1FAIpQLSdcDc7BCvvUMHlNLNPCb62DYx2E0os2gZQin0qvBlAPjlbJyA/viewform?embedded=true"
      width="100%"
      height="800"
      scrolling="no"
    >
      Loading…
    </FormContainer>
  </BaseLayout>
);

export default ContactPage;
